.uploadRow {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imagePreviewModal .previewImg {
  width: 100%;
}

.photo.ccContainer {
  min-height: 120px;
  font-size: 18px;
}

.photoIdContainer {
  max-height: 35vh;
}

.photoIdContainer .checkoutFooter {
  margin-top: 10px;
}

.photoImageContainer {
  position: relative;
  max-width: 150px;
  max-height: 150px;
}

.uploadedPhotoDisplay .photoWithCloseButton {
  display: flex;
  align-items: flex-start;
  max-width: 30%;
  padding: 5px;
}

.uploadedPhotoDisplay .closeButton {
  position: absolute;
  top: 1px;
  right: 1px;
  cursor: pointer;
  margin-left: 3px;
}

.closeButton svg {
  vertical-align: top;
}

@media screen and (max-width: 800px) {
  .photoIdContainer {
    max-height: unset;
  }

  .uploadedPhotoDisplay {
    flex-direction: column;
  }

  .uploadedPhotoDisplay .photoContainer {
    margin-bottom: 5px;
  }

  .photoImageContainer {
    max-width: unset;
    max-height: unset;
  }

  .uploadedPhotoDisplay .photoWithCloseButton {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
    padding: 10px;
  }
}
